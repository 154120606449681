exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-cmeter-js": () => import("./../../../src/pages/cmeter.js" /* webpackChunkName: "component---src-pages-cmeter-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manual-js": () => import("./../../../src/pages/manual.js" /* webpackChunkName: "component---src-pages-manual-js" */),
  "component---src-pages-papers-js": () => import("./../../../src/pages/papers.js" /* webpackChunkName: "component---src-pages-papers-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

